// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

ion-header {
  border-style: solid;
  border-width: 0 0 12px 0;
  border-color: var(--ion-color-primary);
}

a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

.schedule-content {
  display: grid;
  gap: 12px;
}

.font-18 {
  font-size: calc(18 / var(--REM__PIXEL_CONVERSION_FACTOR));
}

.font-14 {
  font-size: calc(14 / var(--REM__PIXEL_CONVERSION_FACTOR));
}

.font-12 {
  font-size: calc(12 / var(--REM__PIXEL_CONVERSION_FACTOR));
}

.block-btn-spinner {
  position: absolute;
  right: 10px;
  top: 50%;
  bottom: 50%;
}

.full-btn-spinner {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
  padding-right: 10px;
}

.header-logo {
  padding: 2px 30px;
  height: 50px;
}

.modal-fullscreen {
  .modal-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.dismissibleToast {
  color: white;
}
