@import url('https://fonts.googleapis.com/css?family=Inter');

:root {
  --ion-color-primary: #ff8a40;
  --ion-color-primary-rgb: 255, 138, 64;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e07938;
  --ion-color-primary-tint: #ff9653;
  --ion-color-primary-light: hsl(23, 100%, 95%);
  --ion-color-primary-tint-2: #ff8a40;

  --ion-color-secondary: #3fabbf;
  --ion-color-secondary-rgb: 63, 171, 191;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #3796a8;
  --ion-color-secondary-tint: #52b3c5;

  --ion-color-tertiary: #834ffe;
  --ion-color-tertiary-rgb: 131, 79, 254;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7346e0;
  --ion-color-tertiary-tint: #8f61fe;

  --ion-color-success: #00dc9f;
  --ion-color-success-rgb: 0, 220, 159;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00c28c;
  --ion-color-success-tint: #1ae0a9;

  --ion-color-warning: #fdda00;
  --ion-color-warning-rgb: 253, 218, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dfc000;
  --ion-color-warning-tint: #fdde1a;

  --ion-color-danger: #f03666;
  --ion-color-danger-rgb: 240, 54, 102;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d3305a;
  --ion-color-danger-tint: #f24a75;

  --ion-color-dark: #666666;
  --ion-color-dark-rgb: 102, 102, 102;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #5a5a5a;
  --ion-color-dark-tint: #757575;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --date-border-top: #d4d4d4;

  /* Fontsize Base is the foundational font all EM metrics are based off of */
  --fontsize_base: 13px;
  --fontsize_mobile: 14px;

  /* GLOBAL: Fontsize for Everything */
  --fontsize_10: 0.833rem;
  --fontsize_11: 0.917rem;
  --fontsize_12: 1rem;
  --fontsize_13: 1.083rem;
  --fontsize_14: 1.167rem;
  --fontsize_16: 1.333rem;
  --fontsize_18: 1.5rem;
  --fontsize_24: 2rem;
  --fontsize_28: 2.333rem;
  --fontsize_36: 3rem;

  font-family: 'Inter';
  font-style: 'normal';
  font-weight: 400;
  line-height: 16px;
  --ion-font-family: 'Inter', sans-serif;

  --mht-light-grey: #f0f0f0;
  --mht-light-orange: #fcf0e7;
  --text-ui-color-text-1-white: #fff;

  //Actabl Colors
  --BLUE_20: #cce0ff;
  --BLUE_60: #3b82f6;
  --BLUE_70: #2563eb;
  --BLUE_90: #1e40af;
  --COOL_GRAY_10: rgba(15, 35, 75, 0.05);
  --COOL_GRAY_20: #dcdfe5;
  --COOL_GRAY_50: #7e8aa0;
  --COOL_GRAY_60: #5f6c81;
  --COOL_GRAY_70: #454e5e;
  --COOL_GRAY_80: #323843;
  --COOL_GRAY_90: #21252c;
  --FILL_TRANSPARENT: rgba(255, 255, 255, 0);
  --GRAY_10: #f6f7f8;
  --GRAY_10_ALPHA: rgba(30, 55, 105, 0.03);
  --GRAY_20: #eaecf0;
  --GRAY_20_ALPHA: rgba(22, 44, 88, 0.07);
  --GRAY_30: #c7cdd6;
  --GRAY_30_ALPHA: rgba(0, 28, 69, 0.19);
  --GRAY_40: #9ba5b5;
  --GRAY_50: rgba(126, 138, 160, 1);
  --GRAY_60: #5c6b84;
  --GRAY_70: #434e60;
  --GREEN_10: #ebfaf0;
  --GREEN_80: #216e4e;
  --LIME_50: #9fd020;
  --INDIGO_10: #f1f4fe;
  --INDIGO_10_ALPHA: rgba(22, 72, 238, 0.04);
  --INDIGO_20: #d7e3fe;
  --INDIGO_40: #6f94fb;
  --INDIGO_60: #4c64ec;
  --INDIGO_70: #2642cf;
  --INDIGO_80: #162b98;
  --INDIGO_90: #131768;
  --ORANGE_10: #fff4e6;
  --ORANGE_20: #ffe2bd;
  --ORANGE_30: #fec57b;
  --ORANGE_40: #faa53d;
  --ORANGE_50: #ff8c00;
  --ORANGE_70: #b65c02;
  --PURPLE_10: #efebff;
  --PURPLE_30: #b8acf6;
  --RED_10: #fbebe9;
  --ROYAL_BLUE_10: #f0f3fe;
  --ROYAL_BLUE_40: rgba(2, 43, 248, 0.57);
  --ROYAL_BLUE_60: #2e4ad6;
  --ROYAL_BLUE_70: #213ab5;
  --ROYAL_BLUE_80: rgba(2, 25, 135, 0.93);
  --ROYAL_BLUE_100: #08144f;
  --ROYAL_BLUE_ALPHA_10: rgba(5, 56, 240, 0.06);
  --TEAL_50: #37b4c3;
  --TEXT_UI_COLOR_TEXT_1: #232d3e;
  --UI_COLOR_DANGER: #e34935;
  --UI_COLOR_FILL_TRANSPARENT: (white, 0%);
  --UI_COLOR_SUCCESS: #279b6b;
  --UI_COLOR_TEXT_1: #192333;
  --UI_COLOR_TEXT_2: #454e5e;
  --UI_COLOR_TEXT_3: #5f6c81;
  --UI_COLOR_TEXT_4: #9ba5b5;
  --UI_COLOR_WARNING: #ff8c00;

  //font size
  --REM__PIXEL_CONVERSION_FACTOR: 16 * 1rem;
}
