// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

:root {
  .image-attachment {
    max-width: 150px;
    max-height: 150px;
    height: auto;
    width: auto;
  }

  .require-contact-info-modal {
    padding: 16px;

    .modal-wrapper {
      background: none;

      .ion-page {
        display: grid;
        align-content: center;
      }
    }

    .require-contact-info {
      background-color: white;
      border-radius: 8px;
      padding: 16px;
    }
  }

  .he-no-padding {
    padding: 0;
  }

  ion-popover::part(content) {
    width: 100%;
  }

  .modal-start-working,
  .modal-stop-working {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
    --max-height: 270px;
    --max-width: 270px;
  }

  .he-dropdown-assignee {
    .alert-wrapper {
      min-width: 80%;
    }
    .padding-30 .alert-radio-label {
      padding-left: 30px;
    }
  }

  .he-dropdown-location {
    .alert-wrapper {
      min-width: 80%;
    }
  }

  .modal-delete-task {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
    --max-height: 320px;
    --max-width: 270px;
  }

  .modal-edit-assignee {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
    --max-height: 270px;
    --max-width: 270px;
  }

  button.action-sheet-button.room-clean-task-status-button {
    color: black;
    & ion-icon {
      order: 2;
      padding-left: 1rem;
    }
  }

  ion-alert.delete-note-alert {
    h2.alert-sub-title,
    div.alert-button-group button {
      color: black;
    }
  }

  ion-badge {
    margin-top: 5px;
    &.location-badge {
      background-color: var(--BLUE_70);
      color: #fff;
      --padding-top: 4px;
      --padding-bottom: 4px;
      --padding-end: 10px;
      --padding-start: 10px;
      width: fit-content;
    }
  }

  ion-input,
  ion-textarea {
    --highlight-color-focused: var(--ion-color-primary) !important;
    --highlight-color-valid: var(--ion-color-primary) !important;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  ::-ms-reveal {
    display: none;
  }
}
